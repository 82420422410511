import React, { useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import qs from 'qs'

import * as actions from '@store/refinanciamento/contratos/actions'
import * as actionsRefin from '@store/refinanciamento/actions'
import QueryMiddleware from '@middlewares/QueryMiddleware'
import routesV2 from '@routes/RefinV2/routes'
import { Page } from '@templates'
import { GridStyled, Loading } from '@atoms'
import { BridgeService } from '@services/bridge'
import { useMobileUserInfo } from '@hooks'
import { ApplicationState } from '@store/types'

const RootPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const userInfo = useMobileUserInfo()
  const { sangueLaranja } = useSelector((state: ApplicationState) => state.session)

  // RESET STORE
  useEffect(() => {
    dispatch(actionsRefin.reset())
  }, [dispatch])

  // FETCH CONTRATOS
  useEffect(() => {
    const params = qs.parse(location?.search, { ignoreQueryPrefix: true })
    if (!BridgeService.isBrowser() && userInfo?.cpf) {
      // Se for APP e carregou o cpf do userinfo
      dispatch(
        actions.contratosResquest({
          cpf: userInfo?.cpf,
          newApiVersion: true,
          sangueLaranja,
          semContratosCallback: () => {
            navigate(routesV2.SEM_EMPRESTIMO)
          },
          successCallback: () => {
            navigate(routesV2.CONTRATOS)
          },
        }),
      )
    } else if (BridgeService.isBrowser()) {
      // Se for WEB carrega o cpf do params
      dispatch(
        actions.contratosResquest({
          cpf: params?.document?.toString() || params?.cpf?.toString(),
          newApiVersion: true,
          sangueLaranja,
          semContratosCallback: () => {
            navigate(routesV2.SEM_EMPRESTIMO)
          },
          successCallback: () => {
            navigate(routesV2.CONTRATOS)
          },
        }),
      )
    }
  }, [dispatch, navigate, location, userInfo?.cpf, sangueLaranja])

  return (
    <Page>
      <QueryMiddleware />
      <GridStyled>
        <Loading width={50} height={50} />
      </GridStyled>
    </Page>
  )
}

export default RootPage
