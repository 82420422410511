import { action, Action } from 'typesafe-actions'

import { SimulacaoResponse } from '@services/postSimulacaoRefinanciamento'
import {
  SimulacaoRequestPayload,
  SalvarLeadRequestPayload,
  InfoParcelas,
  SimulacaoTypes,
  SimulacaoIdPayload,
  Recalculo,
} from '@store/refinanciamento/simulacao/types'
import { StepRefin } from '@utils/enums'

export const setParcelaSelecionada = (payload: InfoParcelas): Action =>
  action(SimulacaoTypes.SET_PARCELA_SELECIONADA, payload)

export const simulacaoRequest = (payload: SimulacaoRequestPayload): Action =>
  action(SimulacaoTypes.SIMULACAO_REQUEST, payload)

export const simulacaoSuccess = (payload: SimulacaoResponse): Action =>
  action(SimulacaoTypes.SIMULACAO_SUCCESS, payload)

export const simulacaoError = (): Action => action(SimulacaoTypes.SIMULACAO_ERROR)

export const salvarLeadRequest = (payload: SalvarLeadRequestPayload): Action =>
  action(SimulacaoTypes.SALVAR_LEAD_REQUEST, payload)

export const salvarLeadSuccess = (): Action => action(SimulacaoTypes.SALVAR_LEAD_SUCCESS)

export const salvarLeadError = (): Action => action(SimulacaoTypes.SALVAR_LEAD_ERROR)

export const setSaldoDevedor = (payload?: number): Action =>
  action(SimulacaoTypes.SET_SALDO_DEVEDOR, payload)

export const setTotalParcelas = (payload?: number): Action =>
  action(SimulacaoTypes.SET_TOTAL_PARCELAS, payload)

export const trackingRefin = (payload: StepRefin): Action =>
  action(SimulacaoTypes.TRACKING_REFIN, payload)

export const selectSimulacao = (payload: string): Action =>
  action(SimulacaoTypes.SELECT_SIMULACAO, payload)

export const setSimulacaoId = (payload: number): Action =>
  action(SimulacaoTypes.SET_SIMULACAO_ID, payload)

export const setSimulacaoRecalculo = (payload: Recalculo): Action =>
  action(SimulacaoTypes.SET_SIMULACAO_RECALCULO, payload)

export const getSimulacaoId = (payload: SimulacaoIdPayload): Action =>
  action(SimulacaoTypes.GET_SIMULACAO_ID, payload)

export const getSimulacaoRecalculo = (payload: SimulacaoIdPayload): Action =>
  action(SimulacaoTypes.GET_SIMULACAO_RECALCULO, payload)

export const setLoadingGetSimulacaoId = (payload: boolean): Action =>
  action(SimulacaoTypes.LOADING_SIMULACAO_ID, payload)

export const setRecallInss = (payload: number): Action =>
  action(SimulacaoTypes.SET_COUNTER_RECALL, payload)

export const clearSimulacao = (): Action => action(SimulacaoTypes.CLEAR_SIMULACAO)

export const setDadosSimulacaoAlterados = (payload: boolean): Action =>
  action(SimulacaoTypes.SET_DADOS_SIMULACAO_ALTERADOS, payload)
