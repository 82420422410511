import { BaseServices, HttpMethod } from '@services/base'
import { Recalculo } from '@store/refinanciamento/simulacao/types'

import { BridgeService } from './bridge'

export default (proposalCode: string, accessToken?: string) => {
  const pathname = `${process.env.REACT_APP_GET_CONTRATO_ORANGEBLOOD}/${proposalCode}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<Recalculo>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
