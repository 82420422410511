import React from 'react'

import { useSelector } from 'react-redux'

import { cpfValue, TemplatesPDF } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'

const SeguroPrestamista = () => {
  const { nome } = useSelector((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { dadosProposta, numeroProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )
  const variant = 'web'

  return (
    <TemplatesPDF.PagePrestamista
      variant={variant}
      proponente={{
        numeroContrato: numeroProposta as number,
        nome,
        nomeSocial: dadosProposta.nomeCliente || '',
        cpf: dadosProposta.cpf || '',
        email: dadosProposta.email || '',
      }}
      dadosSeguro={{
        dtVigenciaInicio: new Date(dadosProposta.primeiroDesconto),
        dtVigenciaFim: new Date(dadosProposta.ultimoDesconto),
      }}
      declaracoes={{
        nomeCliente: dadosProposta.nomeCliente,
        cpfCliente: cpfValue(dadosProposta.cpf),
      }}
      dadosPagamento={{
        checkBoxes: [
          { checked: true, description: 'Financiamento', name: 'Financiamento' },
          { checked: false, description: 'Boleto', name: 'Boleto' },
        ],
        conta: 'BANCO INTER S/A',
        numParcela: dadosProposta.quantidadeDeParcelas,
        valorParcela: parseFloat(dadosProposta.valorParcela),
        premioSeguro: 'Conforme política',
      }}
      dadosCorretor={{
        nomeCorretor: '',
        filial: 'ASSEMBLEIA',
        codigoLiberty: 99003445,
        codigoSusep: 202037072,
        nomeRepresentante: 'BANCO INTER S/A',
        codigo: 8000294,
        cnpj: '00.416.968/0001-01',
        comissao: '',
      }}
      valoresCobertura={{
        valorPorInvalidez: '',
        valorPorMorte: '',
      }}
    />
  )
}

export default SeguroPrestamista
