import { call, put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { CriacaoPropostaSangueLaranjaPayload } from '../types'
import {
  criarPropostaError,
  criarPropostaSangueLaranja,
  criarPropostaSuccess,
  setSangueLaranjaId,
} from '../actions'

export default function* postCriarProposta(action: AnyAction) {
  const payloadSangueLaranja = action.payload as CriacaoPropostaSangueLaranjaPayload
  const { debtBalance } = yield* select(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const {
    requestedAmount,
    cETAa,
    cETAm,
    financedAmount,
    availableAmount,
    firstDueDate,
    lastDueDate,
    monthlyNominalRate,
    yearlyNominalRate,
    amountIOF,
    percentageIOF,
    insuranceFee,
    installmentAmount,
    contractedInterestPercentage,
    totalValueToBePaid,
    txIRAm,
    txIRAa,
    insuranceValuePercentage,
    numberOfInstallments,
  } = yield* select((state: ApplicationState) => state.refinanciamento.simulacao.parcelaAtual)
  const { matriculaSelecionada, contratosSelecionados } = yield* select(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )

  const contratos = contratosSelecionados.map((cs) => cs.numeroContrato)

  const { accessToken } = yield* select((state: ApplicationState) => state.session)
  const { cpf } = yield* select((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { email, telefone } = yield* select(
    (state: ApplicationState) => state.refinanciamento.contato,
  )

  try {
    const { data } = yield* call(
      services.postPropostaSangueLaranja,
      {
        cpf,
        email,
        telefone,
        matricula: matriculaSelecionada,
        qtdeParcelas: numberOfInstallments,
        valorSolicitado: requestedAmount,
        valorParcela: installmentAmount,
        valorLiberado: availableAmount,
        valorIOF: amountIOF,
        valorIOFPercentual: percentageIOF,
        valorSeguro: insuranceFee,
        valorSeguroPercentual: insuranceValuePercentage,
        tarifaCadastro: 0,
        tarifaCadastroPercentual: 0,
        valorFinanciado: financedAmount,
        txIRAm,
        txIRAa,
        txCETAa: cETAa,
        txCETAm: cETAm,
        taxaNominalAm: monthlyNominalRate,
        taxaNomialAa: yearlyNominalRate,
        saldoDevedor: debtBalance,
        dataUltimoVencimento: lastDueDate,
        dataPrimeiroVencimento: firstDueDate,
        jurosContrados: 0,
        jurosContradosPercentual: contractedInterestPercentage,
        valorTotalASerPago: totalValueToBePaid,
        contratos,
        tipoProposta: 'ORANGE_BLOOD',
      },
      accessToken,
    )
    yield put(criarPropostaSuccess(data.codigoProposta))
    yield put(setSangueLaranjaId(data.codigoProposta))
    if (typeof payloadSangueLaranja?.callback === 'function') {
      yield payloadSangueLaranja.callback()
    }
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(criarPropostaError())
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else if (erroTyped.status === HttpStatus.BAD_REQUEST) {
      if (typeof payloadSangueLaranja?.callbackError === 'function') {
        yield payloadSangueLaranja.callbackError()
      }
    } else {
      yield put(
        showError({
          title: 'Erro ao criar proposta',
          message: 'Ocorreu um erro ao criar proposta',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_007_CRIAR_PROPOSTA,
          actionCallback: () => criarPropostaSangueLaranja(payloadSangueLaranja),
        }),
      )
    }
  }
}
