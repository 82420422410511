import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface SalvarPropostaSangueLaranjaRequest {
  codigoProposta: string
  token: string | undefined
  tipoAutenticacao: string
  valor: string
}

export interface DataResponseType {
  sangueLaranjaId: string
}

export default async (data: SalvarPropostaSangueLaranjaRequest, accessToken?: string) => {
  const pathname = process.env.REACT_APP_SALVAR_PROPOSTA_SANGUE_LARANJA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = BaseServices.request<DataResponseType>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
