/**
 * Actions Types
 */
export enum ValidaTypes {
  VALIDA_REQUEST = '@refinanciamento/valida/VALIDAR_REQUEST',
  VALIDA_SET_LOADING = '@refinanciamento/valida/VALIDA_SET_LOADING',
  VALIDA_PENDENCIA_REQUEST = '@refinanciamento/valida/VALIDA_PENDENCIA_REQUEST',
  POST_ISAFE_AUTORIZACAO = '@refinanciamento/valida/POST_ISAFE_AUTORIZACAO',
  POST_FINALIZAR_SANGUE_LARANJA = '@refinanciamento/valida/POST_FINALIZAR_SANGUE_LARANJA',
}

export interface CallBackContatoType {
  readonly contato: boolean
  readonly riscoCpf: number
  readonly pendencia: string
  readonly descricaoPendenciaRisco: string
}
export interface CallBackValidaType {
  readonly tipoPendencia: string
  readonly descricaoPendencia: string
  readonly observacao: string
}

export type ValidaRequestPayload = {
  callback: (params: CallBackContatoType) => void
}

export interface ValidaState {
  readonly loading: boolean
}

export interface PayloadSalvarPropostaSangueLaranja {
  callback: () => void
  erro: () => void
  erroAutorizacao?: () => void
  codigoProposta: string
  token: string
  tipoAutenticacao: string
  valor: string
}

export interface PayloadAutorizacao {
  callback: () => void
  erro: () => void
  erroAutorizacao?: () => void
  token: string
  tipoAutenticacao: string
  valor: string
  conta: string
}
