import { BaseServices, HttpMethod } from '@services/base'

import { BridgeService } from './bridge'

export interface DataCreatePropostaType {
  cpf: string
  email: string
  telefone: string
  matricula: string
  qtdeParcelas: number
  valorSolicitado: number
  valorParcela: number
  valorLiberado: number
  valorIOF: number
  valorIOFPercentual: number
  valorSeguro: number
  valorSeguroPercentual: number
  tarifaCadastro: number
  tarifaCadastroPercentual: number
  valorFinanciado: number
  saldoDevedor: number
  txIRAm: number
  txIRAa: number
  txCETAa: number
  txCETAm: number
  taxaNominalAm: number
  taxaNomialAa: number
  dataUltimoVencimento: string
  dataPrimeiroVencimento: string
  jurosContrados: number
  jurosContradosPercentual: number
  valorTotalASerPago: number
  contratos: Array<string>
  tipoProposta: string
}

export interface CreatePropostaRequest {
  codigoProposta: string
}

export default async (data: DataCreatePropostaType, accessToken?: string) => {
  const pathname = process.env.REACT_APP_CRIAR_PROPOSTA_SANGUE_LARANJA
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_API_HOST
  const headers: Record<string, string> = {}
  if (BridgeService.isBrowser() && accessToken) {
    headers.Authorization = `Bearer ${accessToken}`
  }

  const response = await BaseServices.request<CreatePropostaRequest>({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}
