import { action, Action } from 'typesafe-actions'

import {
  ValidaTypes,
  ValidaRequestPayload,
  PayloadAutorizacao,
  PayloadSalvarPropostaSangueLaranja,
} from './types'

export const validaRequest = (payload: ValidaRequestPayload): Action =>
  action(ValidaTypes.VALIDA_REQUEST, payload)

export const setLoading = (payload: boolean): Action =>
  action(ValidaTypes.VALIDA_SET_LOADING, payload)

export const postIsafeAutorizacao = (payload: PayloadAutorizacao): Action =>
  action(ValidaTypes.POST_ISAFE_AUTORIZACAO, payload)

export const postFinalizarSangueLaranja = (payload: PayloadSalvarPropostaSangueLaranja): Action =>
  action(ValidaTypes.POST_FINALIZAR_SANGUE_LARANJA, payload)
