import React, { useCallback, useEffect } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { ApplicationState } from '@store/types'
import { ConveniosCodes } from '@utils/enums'
import { BridgeService } from '@services/bridge'
import {
  bancosRequest,
  dadosBancariosInssRequest,
} from '@store/refinanciamento/dadosBancarios/actions'
import {
  criarProposta,
  criarPropostaSangueLaranja,
  salvarPropostaRequest,
} from '@store/refinanciamento/proposta/actions'
import { editarLead } from '@store/refinanciamento/documentosAssinatura/actions'
import routes from '@routes/RefinV2/routes'
import { Page } from '@templates'
import { Loading } from '@atoms'
import { StepRefin } from '@utils/enums'
import { getSimulacaoId, trackingRefin } from '@store/refinanciamento/simulacao/actions'
import { ColorWrapper, TextParagraph } from '@atoms/TextParagraph'
import { Colors } from '@utils/Colors'
import { setErroUpdateProposal } from '@store/refinanciamento/documentos/actions'

const CriacaoProposta = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { bancos = [] } = useSelector(
    (state: ApplicationState) => state.refinanciamento.dadosBancarios,
  )
  const { convenioCodigo } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { documentoId, numeroProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )
  const { sangueLaranja } = useSelector((state: ApplicationState) => state.session)

  const callProposta = useCallback(() => {
    if (sangueLaranja) {
      if (!numeroProposta) {
        dispatch(
          getSimulacaoId({
            callback: () => {
              dispatch(
                criarPropostaSangueLaranja({
                  callback: () => {
                    dispatch(
                      editarLead({
                        callback: () => {
                          navigate(routes.CARREGAMENTO_DADOS)
                        },
                      }),
                    )
                  },
                  callbackError: () => {
                    navigate(routes.NAO_LIBERADO)
                  },
                }),
              )
            },
          }),
        )
      } else {
        navigate(routes.CARREGAMENTO_DADOS)
      }
    } else if (!numeroProposta) {
      if (BridgeService.isBrowser()) {
        dispatch(
          getSimulacaoId({
            callback: () => {
              dispatch(
                criarProposta({
                  callback: () => {
                    dispatch(
                      editarLead({
                        situacao: 'Documentos',
                        callback: () => {
                          navigate(routes.ENDERECO)
                        },
                      }),
                    )
                  },
                  callbackError: () => {
                    navigate(routes.NAO_LIBERADO)
                  },
                }),
              )
            },
          }),
        )
      } else if (!BridgeService.isBrowser()) {
        if (convenioCodigo && convenioCodigo === ConveniosCodes.INSS) {
          if (!bancos || bancos.length === 0) {
            dispatch(bancosRequest())
          }
          dispatch(dadosBancariosInssRequest())
        }
        dispatch(
          getSimulacaoId({
            callback: () => {
              dispatch(
                criarProposta({
                  callback: () => {
                    dispatch(
                      editarLead({
                        situacao: 'Documentos',
                        callback: () => {
                          if (!documentoId) {
                            dispatch(
                              salvarPropostaRequest({
                                callback: () => {
                                  dispatch(trackingRefin(StepRefin.INICIO_CAPTURA))
                                  navigate(routes.CARREGAMENTO_DADOS)
                                },
                              }),
                            )
                          }
                        },
                      }),
                    )
                  },
                  callbackError: () => {
                    navigate(routes.NAO_LIBERADO)
                  },
                }),
              )
            },
          }),
        )
      }
    } else if (BridgeService.isBrowser()) {
      // Se já tinha numero de proposta segue o fluxo de editar lead e endereco no web.
      dispatch(
        editarLead({
          situacao: 'Documentos',
          callback: () => {
            navigate(routes.ENDERECO)
          },
        }),
      )
    } else {
      // Se já tinha numero de proposta segue o fluxo de editar lead e salvar proposta no app.
      if (convenioCodigo && convenioCodigo === ConveniosCodes.INSS) {
        if (!bancos || bancos.length === 0) {
          dispatch(bancosRequest())
        }
        dispatch(dadosBancariosInssRequest())
      }
      dispatch(
        editarLead({
          situacao: 'Documentos',
          callback: () => {
            if (!documentoId) {
              dispatch(
                salvarPropostaRequest({
                  callback: () => {
                    dispatch(trackingRefin(StepRefin.INICIO_CAPTURA))
                    navigate(routes.CARREGAMENTO_DADOS)
                  },
                }),
              )
            }
          },
        }),
      )
    }
  }, [bancos, convenioCodigo, dispatch, documentoId, navigate, numeroProposta, sangueLaranja])

  useEffect(() => {
    dispatch(setErroUpdateProposal(false))
    callProposta()
  }, [callProposta, dispatch])

  return (
    <>
      <Page>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '100px',
          }}
        >
          <Loading width="50px" height="50px" />
          <ColorWrapper color={Colors.GRAY500} style={{ textAlign: 'center' }} fontWeight="600">
            <TextParagraph variant="body-1">Criando a proposta...</TextParagraph>
          </ColorWrapper>
        </div>
      </Page>
    </>
  )
}
export default CriacaoProposta
