import { call, put, select } from 'typed-redux-saga'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { ErrorCodes } from '@utils/enums'
import { ApplicationState } from '@store/types'
import { AnyAction } from '@reduxjs/toolkit'
import { BridgeService } from '@services/bridge'
import { HttpStatus } from '@services/base'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { SimulacaoIdPayload } from '../types'
import { setLoadingGetSimulacaoId, getSimulacaoRecalculo, setSimulacaoRecalculo } from '../actions'

export default function* getSimulacaoRecalculoSaga(action: AnyAction) {
  const payload = action.payload as SimulacaoIdPayload
  const { contratosSelecionados } = yield* select(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  let numeroProposta = ''

  try {
    yield put(setLoadingGetSimulacaoId(true))

    const { accessToken } = yield* select((state: ApplicationState) => state.session)

    contratosSelecionados.map((contrato) => {
      numeroProposta = contrato.numeroProposta
      return numeroProposta
    })

    const { data } = yield* call(services.getSimulacaoProposta, numeroProposta, accessToken)

    yield put(setSimulacaoRecalculo(data))
    yield put(setLoadingGetSimulacaoId(false))
    yield payload.callback()
  } catch (error) {
    const erroTyped = error as ErroType
    yield put(setLoadingGetSimulacaoId(false))
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro ao buscar dados da simulação',
          message: 'Ocorreu um erro ao buscar os dados da simulação',
          actionTitle: 'Tentar Novamente',
          code: ErrorCodes.RE_006_BUSCAR_IDSIMULACAO,
          actionCallback: () => getSimulacaoRecalculo(payload),
        }),
      )
    }
  }
}
