import { AnyAction } from 'redux'
import { call, select } from 'typed-redux-saga'

import { HttpStatus } from '@services/base'
import postSalvarPropostaSangueLaranja from '@services/postSalvarPropostaSangueLaranja'
import { ApplicationState } from '@store/types'

import { PayloadSalvarPropostaSangueLaranja } from '../types'

interface ErrorType {
  data: string
  status: HttpStatus
}
export default function* postFinalizareISafeSangueLaranja(action: AnyAction) {
  const payload = action?.payload as PayloadSalvarPropostaSangueLaranja
  const { sangueLaranjaId } = yield* select(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )

  try {
    yield* call(postSalvarPropostaSangueLaranja, {
      codigoProposta: String(sangueLaranjaId),
      tipoAutenticacao: payload.tipoAutenticacao,
      token: payload.token,
      valor: payload.valor,
    })
    payload.callback()
  } catch (error) {
    const errorResponse = error as ErrorType
    if (errorResponse?.status === HttpStatus.FORBIDDEN) {
      if (payload?.erroAutorizacao) {
        yield payload?.erroAutorizacao()
      }
    } else {
      yield payload?.erro()
    }
  }
}
