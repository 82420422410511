import React, { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { differenceInDays } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import qs from 'qs'

import * as navigationActions from '@store/ui/navigation/actions'
import * as sessionActions from '@store/session/actions'
import { ApplicationState } from '@store/types'

const QueryMiddleware = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  const { sessionId, sessionCreateDate } = useSelector((state: ApplicationState) => state.session)

  const handleIsApp = (origem: string) => Boolean(origem && origem === 'app')

  useEffect(() => {
    const params = qs.parse(location?.search, { ignoreQueryPrefix: true })

    dispatch(navigationActions.setOrigem(params?.origem?.toString() || 'site'))
    dispatch(navigationActions.setIsApp(handleIsApp(params?.origem?.toString() || 'site')))
    dispatch(navigationActions.setOrigemGoBack(params?.origemgoback?.toString()) || undefined)

    dispatch(sessionActions.setUtmSource(getUtmSourceFromParams(params)))
    dispatch(sessionActions.setUtmMedium(getUtmMediumFromParams(params)))
    dispatch(sessionActions.setUtmCampaign(getUtmCampaignFromParams(params)))
    dispatch(sessionActions.setUtmContent(getUtmContentFromParams(params)))
    dispatch(sessionActions.setFacebookId(params?.fbclid ? params?.fbclid.toString() : ''))
    dispatch(sessionActions.setGaClientId(params?.gaid ? params?.gaid.toString() : ''))
    dispatch(sessionActions.setGaClid(params?.gclid ? params?.gclid.toString() : ''))
    dispatch(
      sessionActions.setAccessToken(params?.accessToken ? params?.accessToken.toString() : ''),
    )
    dispatch(sessionActions.setUserName(params?.username ? params?.username.toString() : ''))
    dispatch(sessionActions.setCpf(params?.document ? params?.document.toString() : ''))
    dispatch(sessionActions.setCampanha(params?.campanha ? params?.campanha.toString() : ''))
    dispatch(sessionActions.setSangueLaranja(params?.sangueLaranja === 'true'))
  }, [location?.search, dispatch])

  useEffect(() => {
    if (
      !sessionId ||
      !sessionCreateDate ||
      differenceInDays(new Date(), new Date(sessionCreateDate)) > 0
    ) {
      dispatch(sessionActions.setSessionId(uuidv4()))
    }
  }, [sessionCreateDate, sessionId, dispatch])

  return <></>
}

const getUtmSourceFromParams = (params: qs.ParsedQs) => {
  if (params?.utm_source) return params.utm_source.toString()
  if (params?.utmSource) return params.utmSource.toString()
  return ''
}

const getUtmMediumFromParams = (params: qs.ParsedQs) => {
  if (params?.utm_medium) return params.utm_medium.toString()
  if (params?.utmMedium) return params.utmMedium.toString()
  if (params?.utmSMedium) return params.utmSMedium.toString()
  return ''
}

const getUtmCampaignFromParams = (params: qs.ParsedQs) => {
  if (params?.utm_campaign) return params.utm_campaign.toString()
  if (params?.utmCampaign) return params.utmCampaign.toString()
  if (params?.utmSCampaign) return params.utmSCampaign.toString()
  return ''
}

const getUtmContentFromParams = (params: qs.ParsedQs) => {
  if (params?.utm_content) return params.utm_content.toString()
  if (params?.utmContent) return params.utmContent.toString()
  if (params?.utmSContent) return params.utmSContent.toString()
  return ''
}

export default QueryMiddleware
