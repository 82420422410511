import React from 'react'

import { useSelector } from 'react-redux'

import { Button } from '@interco/inter-ui/components/Button'
import { Paragraph } from '@atoms'
import { ErrorIcon } from '@icons/Signals'
import { GridStyled } from '@atoms'
import { validaEnvHomeByBridge } from '@utils/functions'
import { Page } from '@templates'
import { Colors } from '@utils/Colors'
import { ApplicationState } from '@store/types'

const NaoLiberado = () => {
  const { origemGoBack, isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  return (
    <Page
      footerTwoButtonsSpace="200px"
      showLeftIcon={false}
      stickyFooter={
        <Button
          variant="primary"
          onClick={() => {
            window.location.href = validaEnvHomeByBridge(String(origemGoBack), isApp) || ''
          }}
        >
          Voltar ao início
        </Button>
      }
    >
      <GridStyled>
        <ErrorIcon />
      </GridStyled>

      <Paragraph
        variant="sora"
        fontSize="24px"
        fontWeight="600"
        lineHeight="28px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="40px 0 8px"
      >
        Não é possível entregar o crédito solicitado
      </Paragraph>
      <Paragraph textAlign="left" variant="inter">
        No momento, não é possível oferecer o crédito solicitado. Isso pode acontecer pelo uso total
        da margem ou por nossas políticas de crédito.
      </Paragraph>
      <Paragraph textAlign="left" variant="inter">
        Verifique com o órgão responsável por seu salário ou benefício e tente novamente.
      </Paragraph>
    </Page>
  )
}

export default NaoLiberado
