import React from 'react'

import { Route, BrowserRouter, Routes } from 'react-router-dom'

import { BridgeService } from '@services/bridge'
import { GoBackConfig } from '@utils/goBackConfig'
import {
  NaoLiberado,
  SemEmprestimo,
  DadosRecebidos,
  RootPage,
  ConfirmarContato,
  ResultadoSimulacao,
  Parcelamento,
  Contratos,
  Autorizacao,
  Simulacao,
  OnboardingContrato,
  ConferirRefinanciamento,
  ListaDocumentos,
  VisualizarDocumento,
} from '@pages'
import ContratosV2 from '@pages/RefinV2/EtapaSimular/Contratos'
import AutorizacaoV2 from '@pages/RefinV2/EtapaSimular/Contratos/BottomsheetInss/Autorizacao'
import OfertaSeguro from '@pages/RefinV2/EtapaSimular/OfertaSeguro'
import ResultadoSimulacaoV2 from '@pages/RefinV2/EtapaSimular/ResultadoSimulacao'
import DadosRecebidosV2 from '@pages/RefinV2/EtapaSimular/DadosRecebidos'
import CarregamentoDados from '@pages/RefinV2/EtapaSimular/CarregamentoDados'
import Revisao from '@pages/RefinV2/EtapaFormalizar/Revisao'
import VisualizarTermo from '@pages/RefinV2/EtapaFormalizar/Termos/VisualizadorTermo'
import Termos from '@pages/RefinV2/EtapaFormalizar/Termos'
import Sucesso from '@pages/RefinV2/EtapaFormalizar/Sucesso'
import NaoLiberadoV2 from '@pages/RefinV2/EtapaSimular/NaoLiberado'
import StatusDocumentos from '@pages/RefinV2/EtapaFormalizar/Documentos/StatusDocumentos'
import ModoEnvio from '@pages/RefinV2/EtapaFormalizar/Documentos/ModoEnvio'
import FotoDocumento from '@pages/RefinV2/EtapaFormalizar/Documentos/FotoDocumento'
import SemEmprestimoV2 from '@pages/RefinV2/EtapaSimular/SemEmprestimo'
import Endereco from '@pages/RefinV2/EtapaFormalizar/DadosPessoais/Endereco'
import DadosBancarios from '@pages/RefinV2/EtapaFormalizar/DadosPessoais/DadosBancarios'
import CriacaoProposta from '@pages/RefinV2/EtapaFormalizar/DadosPessoais/CriacaoProposta'
import InssIndisponivel from '@pages/RefinV2/EtapaSimular/InssIndisponivel'
import ModoEnvioContracheque from '@pages/RefinV2/EtapaFormalizar/Documentos/ModoEnvioContracheque'
import FotoContraCheque from '@pages/RefinV2/EtapaFormalizar/Documentos/FotoContraCheque'

import routesV2 from './RefinV2/routes'
import routesV1 from './Refin/routes'

const RoutesRefin: React.FC = () => (
  <>
    {BridgeService.isBrowser() ? (
      <BrowserRouter>
        <GoBackConfig />
        <Routes>
          <Route path={routesV1.VISUALIZAR_DOCUMENTO} element={<VisualizarDocumento />} />
          <Route path={routesV1.LISTA_DOCUMENTOS} element={<ListaDocumentos />} />
          <Route path={routesV1.CONFERIR_REFINANCIAMENTO} element={<ConferirRefinanciamento />} />
          <Route path={routesV1.ONBOARDING_CONTRATO} element={<OnboardingContrato />} />
          <Route path={routesV1.SIMULACAO} element={<Simulacao />} />
          <Route path={routesV1.AUTORIZACAO} element={<Autorizacao />} />
          <Route path={routesV1.CONTRATOS} element={<Contratos />} />
          <Route path={routesV1.PARCELAMENTO} element={<Parcelamento />} />
          <Route path={routesV1.RESULTADO} element={<ResultadoSimulacao />} />
          <Route path={routesV1.CONFIRMAR_CONTATO} element={<ConfirmarContato />} />
          <Route path={routesV1.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
          <Route path={routesV1.NAO_LIBERADO} element={<NaoLiberado />} />
          <Route path={routesV1.ROOT} element={<RootPage />} />
          <Route path={routesV1.SEM_EMPRESTIMO} element={<SemEmprestimo />} />

          <Route path={routesV2.ROOT} element={<RootPage />} />
          <Route path={routesV2.CONTRATOS} element={<ContratosV2 />} />
          <Route path={routesV2.AUTORIZACAO} element={<AutorizacaoV2 />} />
          <Route path={routesV2.OFERTA_SEGURO} element={<OfertaSeguro />} />
          <Route path={routesV2.RESULTADO_SIMULACAO} element={<ResultadoSimulacaoV2 />} />
          <Route path={routesV2.DADOS_RECEBIDOS} element={<DadosRecebidosV2 />} />
          <Route path={routesV2.CARREGAMENTO_DADOS} element={<CarregamentoDados />} />
          <Route path={routesV2.STATUS_DOCUMENTO} element={<StatusDocumentos />} />
          <Route path={routesV2.MODO_ENVIO} element={<ModoEnvio />} />
          <Route path={routesV2.FOTO_DOCUMENTO} element={<FotoDocumento />} />
          <Route path={routesV2.MODO_ENVIO_CONTRA_CHEQUE} element={<ModoEnvioContracheque />} />
          <Route path={routesV2.FOTO_CONTRA_CHEQUE} element={<FotoContraCheque />} />
          <Route path={routesV2.REVISAO} element={<Revisao />} />
          <Route path={routesV2.TERMO_VISUALIZACAO} element={<VisualizarTermo />} />
          <Route path={routesV2.TERMOS_CONTRATACAO} element={<Termos />} />
          <Route path={routesV2.SUCESSO} element={<Sucesso />} />
          <Route path={routesV2.NAO_LIBERADO} element={<NaoLiberadoV2 />} />
          <Route path={routesV2.SEM_EMPRESTIMO} element={<SemEmprestimoV2 />} />
          <Route path={routesV2.ENDERECO} element={<Endereco />} />
          <Route path={routesV2.DADOS_BANCARIOS} element={<DadosBancarios />} />
          <Route path={routesV2.CRIACAO_PROPOSTA} element={<CriacaoProposta />} />
          <Route path={routesV2.INSS_INDISPONIVEL} element={<InssIndisponivel />} />
          <Route path={routesV2.SEM_EMPRESTIMO} element={<SemEmprestimoV2 />} />
        </Routes>
      </BrowserRouter>
    ) : (
      <BrowserRouter>
        <GoBackConfig />
        <Routes>
          <Route path={routesV1.VISUALIZAR_DOCUMENTO} element={<VisualizarDocumento />} />
          <Route path={routesV1.LISTA_DOCUMENTOS} element={<ListaDocumentos />} />
          <Route path={routesV1.CONFERIR_REFINANCIAMENTO} element={<ConferirRefinanciamento />} />
          <Route path={routesV1.ONBOARDING_CONTRATO} element={<OnboardingContrato />} />
          <Route path={routesV1.SIMULACAO} element={<Simulacao />} />
          <Route path={routesV1.AUTORIZACAO} element={<Autorizacao />} />
          <Route path={routesV1.CONTRATOS} element={<Contratos />} />
          <Route path={routesV1.PARCELAMENTO} element={<Parcelamento />} />
          <Route path={routesV1.RESULTADO} element={<ResultadoSimulacao />} />
          <Route path={routesV1.CONFIRMAR_CONTATO} element={<ConfirmarContato />} />
          <Route path={routesV1.DADOS_RECEBIDOS} element={<DadosRecebidos />} />
          <Route path={routesV1.NAO_LIBERADO} element={<NaoLiberado />} />
          <Route path={routesV1.ROOT} element={<RootPage />} />
          <Route path={routesV1.SEM_EMPRESTIMO} element={<SemEmprestimo />} />

          <Route path={routesV2.ROOT} element={<RootPage />} />
          <Route path={routesV2.CONTRATOS} element={<ContratosV2 />} />
          <Route path={routesV2.AUTORIZACAO} element={<AutorizacaoV2 />} />
          <Route path={routesV2.OFERTA_SEGURO} element={<OfertaSeguro />} />
          <Route path={routesV2.RESULTADO_SIMULACAO} element={<ResultadoSimulacaoV2 />} />
          <Route path={routesV2.DADOS_RECEBIDOS} element={<DadosRecebidosV2 />} />
          <Route path={routesV2.CARREGAMENTO_DADOS} element={<CarregamentoDados />} />
          <Route path={routesV2.STATUS_DOCUMENTO} element={<StatusDocumentos />} />
          <Route path={routesV2.MODO_ENVIO} element={<ModoEnvio />} />
          <Route path={routesV2.FOTO_DOCUMENTO} element={<FotoDocumento />} />
          <Route path={routesV2.MODO_ENVIO_CONTRA_CHEQUE} element={<ModoEnvioContracheque />} />
          <Route path={routesV2.FOTO_CONTRA_CHEQUE} element={<FotoContraCheque />} />

          <Route path={routesV2.REVISAO} element={<Revisao />} />
          <Route path={routesV2.TERMO_VISUALIZACAO} element={<VisualizarTermo />} />
          <Route path={routesV2.TERMOS_CONTRATACAO} element={<Termos />} />
          <Route path={routesV2.SUCESSO} element={<Sucesso />} />
          <Route path={routesV2.NAO_LIBERADO} element={<NaoLiberadoV2 />} />
          <Route path={routesV2.SEM_EMPRESTIMO} element={<SemEmprestimoV2 />} />
          <Route path={routesV2.ENDERECO} element={<Endereco />} />
          <Route path={routesV2.DADOS_BANCARIOS} element={<DadosBancarios />} />
          <Route path={routesV2.CRIACAO_PROPOSTA} element={<CriacaoProposta />} />
          <Route path={routesV2.INSS_INDISPONIVEL} element={<InssIndisponivel />} />
          <Route path={routesV2.SEM_EMPRESTIMO} element={<SemEmprestimoV2 />} />
        </Routes>
      </BrowserRouter>
    )}
  </>
)
export default RoutesRefin
