import React, { useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { Page } from '@templates'
import { formatCurrency } from '@utils/masks'
import { Box, Paragraph } from '@atoms'
import { Button } from '@interco/inter-ui/components/Button'
import { ListaDescricao, ListaDetalhes } from '@molecules'
import { Separator } from '@atoms'
import { ApplicationState } from '@store/types'
import ResumoDePedido from '@interco/icons/core/files/ic_document'
import ChevronRight from '@interco/icons/core/action-navigation/ic_chevron_right'
import Help from '@interco/icons/core/status/ic_help_circle'
import { Colors } from '@utils/Colors'
import routes from '@routes/Refin/routes'
import {
  dadosPropostaAntigaRequest,
  salvarPropostaRequest,
} from '@store/refinanciamento/proposta/actions'

import { Bold, CircleIcon, Line, StyledSeparator } from './styles'
import { BottomSheetContratos } from './Bottomsheets/BottomSheetContratos'
import { BottomSheetCet } from './Bottomsheets/BottomSheetCet'

interface OwnProps {
  children: string
}

export function roundToTwo(numero: number): number {
  return Math.round((numero + Number.EPSILON) * 100) / 100
}

const ConferirRefinanciamento = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [bottomSheetContratos, setShowBottomSheetContratos] = useState(false)
  const [bottomSheetCet, setShowBottomSheetCet] = useState(false)
  const { parcelaAtual, debtBalance } = useSelector(
    (state: ApplicationState) => state.refinanciamento.simulacao,
  )
  const { convenioSelecionado, matriculaSelecionada, contratosSelecionados } = useSelector(
    (state: ApplicationState) => state.refinanciamento.contratos,
  )
  const { conta } = useSelector((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { loadingProposta } = useSelector(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )

  const Titulo = ({ children }: OwnProps) => (
    <Paragraph
      variant="sora"
      fontSize="16px"
      lineHeight="20px"
      fontWeight={600}
      color={Colors.GRAY400}
      margin="24px 0 8px"
      textAlign="center"
    >
      {children}
    </Paragraph>
  )

  const Texto = ({ children }: OwnProps) => (
    <Paragraph
      variant="inter"
      fontWeight="normal"
      fontSize="12px"
      lineHeight="15px"
      color={Colors.GRAY400}
      margin="4px 0"
      textAlign="center"
    >
      {children}
    </Paragraph>
  )

  return (
    <Page
      stickyFooter={
        <Button
          variant="primary"
          isLoading={loadingProposta}
          disabled={loadingProposta}
          onClick={() => {
            dispatch(
              dadosPropostaAntigaRequest({
                callback: () => {
                  dispatch(
                    salvarPropostaRequest({
                      callback: () => {
                        navigate(routes.LISTA_DOCUMENTOS)
                      },
                    }),
                  )
                },
              }),
            )
          }}
        >
          Contratar
        </Button>
      }
    >
      <Paragraph
        fontFamily="sora"
        fontSize="24px"
        lineHeight="30px"
        fontWeight={600}
        color={Colors.GRAY500}
        textAlign="left"
        margin="0 0 8px"
      >
        Confira seu refinanciamento.
      </Paragraph>
      <Titulo>Troco liberado pra você</Titulo>
      <Paragraph
        variant="sora"
        fontWeight={600}
        fontSize="24px"
        lineHeight="30px"
        color={Colors.SUCCESS500}
        margin="0"
        textAlign="center"
      >
        {formatCurrency(parcelaAtual.availableAmount)}
      </Paragraph>
      <Texto>Ao refinanciar, esse valor cai direto na sua conta</Texto>

      <Separator variant="small" margin="24px 0" />
      <ListaDetalhes
        title="Suas novas parcelas"
        list={[
          {
            title: 'Suas novas parcelas',
            values: [
              `${parcelaAtual.numberOfInstallments} x ${formatCurrency(
                parcelaAtual.installmentAmount,
              )}`,
            ],
          },
          {
            title: 'Valor total financiado',
            values: [
              formatCurrency(parcelaAtual.financedAmount + parcelaAtual.contractualInterest),
            ],
          },
        ]}
      />

      <Separator variant="small" margin="24px 0" />
      <ListaDetalhes
        title="Detalhamento dos valores"
        list={[
          {
            title: 'Saldo devedor pré-refinanciamento',
            values: [formatCurrency(debtBalance)],
          },
          {
            title: 'Troco liberado',
            values: [formatCurrency(parcelaAtual.availableAmount)],
          },
          {
            title: 'Novo valor disponível',
            values: [
              formatCurrency(parcelaAtual.financedAmount + parcelaAtual.contractualInterest),
            ],
          },
        ]}
      />

      <Separator variant="small" margin="24px 0" />
      <ListaDetalhes
        title="Taxas"
        list={[
          {
            title: 'Taxa de juros a partir de',
            values: [`(${roundToTwo(parcelaAtual.monthlyNominalRate)}% a.m.)`],
          },
          {
            title: 'IOF',
            values: [
              `(${roundToTwo(parcelaAtual.percentageIOF)}%) ${formatCurrency(
                parcelaAtual.amountIOF,
              )}`,
            ],
          },
          {
            title: 'Custo efetivo total (CET)',
            values: [`(${roundToTwo(parcelaAtual.cETAa)}% a.a.)`],
          },
          {
            title: 'Valor do CET',
            iconBesideItemTitle: (
              <Help
                color={Colors.PRIMARY500}
                width={16}
                height={16}
                onClick={() => setShowBottomSheetCet(true)}
              />
            ),
            values: [`${formatCurrency(parcelaAtual.contractualInterest)}`],
          },
        ]}
      />

      <Separator height="2px" margin="24px 0" />

      <ListaDetalhes
        title=""
        list={[
          {
            title: 'Novo valor total refinanciado',
            values: [
              formatCurrency(parcelaAtual.financedAmount + parcelaAtual.contractualInterest),
            ],
            color: Colors.GRAY500,
          },
        ]}
      />

      <Box margin="16px 0px" key="box-conta" direction="column" align="flex-start" padding="16px">
        <Paragraph
          color={Colors.GRAY400}
          fontSize="14px"
          fontWeight={700}
          lineHeight="17px"
          margin="0 0 8px 0"
        >
          Conta Inter
        </Paragraph>
        <Line>
          <Paragraph fontSize="14px" lineHeight="17px">
            ag <Bold>0001</Bold>
          </Paragraph>
          <Paragraph fontSize="14px" lineHeight="17px" margin="0 0 0 10px">
            conta <Bold>{conta}</Bold>
          </Paragraph>
        </Line>
        <StyledSeparator />
        <Paragraph fontSize="14px" lineHeight="17px" color={Colors.GRAY500}>
          Seu refinanciamento vai cair nessa conta
        </Paragraph>
      </Box>

      <ListaDescricao
        id="contratos-refinanciamento"
        margin="30px 0px 32px 0px"
        alignAllItems="center"
        iconLeft={
          <CircleIcon>
            <ResumoDePedido height={24} width={24} color={Colors.GRAY500} />
          </CircleIcon>
        }
        iconRight={<ChevronRight height={24} width={24} />}
        onClick={() => {
          setShowBottomSheetContratos(true)
        }}
      >
        <Paragraph fontWeight="bold" fontSize="12px" color={Colors.GRAY500} margin="0 0 4px">
          Contratos desse refinanciamento
        </Paragraph>
        <Paragraph fontSize="12px" color={Colors.GRAY400} margin="0">
          Esses são os contratos financiados nessa simulação
        </Paragraph>
      </ListaDescricao>

      {bottomSheetContratos ? (
        <BottomSheetContratos
          convenioSelecionado={convenioSelecionado}
          matriculaSelecionada={matriculaSelecionada}
          contratosSelecionados={contratosSelecionados}
          setShowBottomSheetContratos={setShowBottomSheetContratos}
        />
      ) : (
        <></>
      )}
      {bottomSheetCet ? <BottomSheetCet setShowBottomSheetCet={setShowBottomSheetCet} /> : <></>}
    </Page>
  )
}

export default ConferirRefinanciamento
