import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { HttpStatus } from '@services/base'
import { BridgeService } from '@services/bridge'
import { logout } from '@utils/functions'
import { ErroType } from '@utils/types'

import { finalizarPropostaErro, finalizarPropostaSuccess } from '../actions'

export default function* postFinalizarPropostaSaga({ payload }: AnyAction) {
  const { numeroProposta } = yield* select(
    (state: ApplicationState) => state.refinanciamento.proposta,
  )
  const { cpf } = yield* select((state: ApplicationState) => state.refinanciamento.dadosPessoais)
  const { email, telefone } = yield* select(
    (state: ApplicationState) => state.refinanciamento.contato,
  )
  const { accessToken, utmSource } = yield* select((state: ApplicationState) => state.session)

  try {
    if (utmSource === 'Recalculo') {
      yield services.postPropostaReassinar(
        {
          proposalCode: String(numeroProposta),
        },
        accessToken,
      )
    } else {
      yield services.postPropostaFinalizar(
        {
          codigoProposta: String(numeroProposta),
          cpf: BridgeService.isBrowser() ? cpf : process.env.REACT_APP_MOCK_CPF || '',
          email,
          telefone,
          produto: 'Refinanciamento',
        },
        accessToken,
      )
    }

    yield put(finalizarPropostaSuccess())
    yield payload.callback()
  } catch (error) {
    yield put(finalizarPropostaErro())
    const erroTyped = error as ErroType
    if (BridgeService.isBrowser() && erroTyped.status === HttpStatus.UNAUTHORIZED) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          functionCallback: () => logout(),
        }),
      )
    } else if (erroTyped.status === HttpStatus.NOT_FOUND) {
      yield put(
        showError({
          title: 'Ocorreu um erro',
          message: 'Ocorreu um erro ao validar o envio da biometria, por favor tente novamente.',
          functionCallback: () => payload.callbackTryAgain(),
          actionTitle: 'Refazer biometria',
          block: true,
        }),
      )
    }
  }
}
