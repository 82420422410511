/**
 * Actions Types
 */

export enum PropostaTypes {
  DADOS_PROPOSTA_SUCCESS = '@refinanciamento/proposta/DADOS_PROPOSTA_SUCCESS',
  DADOS_PROPOSTA_ERROR = '@refinanciamento/proposta/DADOS_PROPOSTA_ERROR',
  DADOS_PROPOSTA_REQUEST = '@refinanciamento/proposta/DADOS_PROPOSTA_REQUEST',

  CRIAR_PROPOSTA = '@refinanciamento/proposta/criacao/CRIAR_PROPOSTA',
  CRIAR_PROPOSTA_SANGUE_LARANJA = '@refinanciamento/proposta/criacao/CRIAR_PROPOSTA_SANGUE_LARANJA',
  CRIAR_PROPOSTA_SUCCESS = '@refinanciamento/proposta/criacao/CRIAR_PROPOSTA_SUCCESS',
  CRIAR_PROPOSTA_ERROR = '@refinanciamento/proposta/criacao/CRIAR_PROPOSTA_ERROR',
  SET_NUMERO_PROPOSTA = '@refinanciamento/proposta/criacao/SET_NUMERO_PROPOSTA',

  SET_ID_ITERACAO = '@refinanciamento/proposta/criacao/SET_ID_ITERACAO',
  SET_TIPO_DOCUMENTO = '@refinanciamento/proposta/criacao/SET_TIPO_DOCUMENTO',
  SET_DOCUMENTO_ID = '@refinanciamento/proposta/criacao/SET_DOCUMENTO_ID',

  SALVAR_PROPOSTA_REQUEST = '@refinanciamento/proposta/SALVAR_PROPOSTA_REQUEST',
  SALVAR_PROPOSTA_SANGUE_LARANJA_REQUEST = '@refinanciamento/proposta/SALVAR_PROPOSTA_SANGUE_LARANJA_REQUEST',
  SALVAR_PROPOSTA_SUCCESS = '@refinanciamento/proposta/SALVAR_PROPOSTA_SUCCESS',
  SALVAR_PROPOSTA_ERROR = '@refinanciamento/proposta/SALVAR_PROPOSTA_ERROR',

  DADOS_PROPOSTA_ANTIGA_SUCCESS = '@refinanciamento/proposta/DADOS_PROPOSTA_ANTIGA_SUCCESS',
  DADOS_PROPOSTA_ANTIGA_ERROR = '@refinanciamento/proposta/DADOS_PROPOSTA_ANTIGA_ERROR',
  DADOS_PROPOSTA_ANTIGA_REQUEST = '@refinanciamento/proposta/DADOS_PROPOSTA_ANTIGA_REQUEST',

  INFO_VALIDA = '@refinanciamento/proposta/dadosBancarios/INFO_VALIDA',
  SET_CONTATO = '@refinanciamento/proposta/dadosBancarios/SET_CONTATO',

  SET_VALIDA_LOADING = '@refinanciamento/proposta/dadosBancarios/SET_VALIDA_LOADING',

  SET_SANGUE_LARANJA_PROPOSTA_ID = '@refinanciamento/simulacao/SET_SANGUE_LARANJA_PROPOSTA_ID',
  GET_SANGUE_LARANJA_PROPOSTA_ID = '@refinanciamento/simulacao/GET_SANGUE_LARANJA_PROPOSTA_ID',

  RESET = 'RESET',
}

export interface DocumentosEnvioType {
  idDocumento?: string
  idTipoDocumento: string
  nomeTipoDocumento?: string
  status?: string
}

export interface PropostaState {
  readonly dadosProposta: PropostaApi
  readonly dadosPropostaAntiga: PropostaApi
  readonly dadosPropostaError: boolean
  readonly loadingProposta: boolean
  readonly numeroProposta?: string | number
  readonly loadingCriarProposta: boolean
  readonly errorCriarProposta: boolean

  readonly tipoDocumento: DocumentosEnvioType[] | undefined
  readonly idIteracao: string
  readonly documentoId: string

  readonly loadingSalvarProposta: boolean
  readonly propostaEnviada: boolean
  readonly erroSalvarProposta: boolean

  readonly contato?: boolean
  readonly validaLoading?: boolean
  readonly sangueLaranjaId?: string
}

export interface PropostaApi {
  numeroParcelas: number
  valorParcela: string
  taxa: string
  cet: string
  percentualIOF: string
  valorIOF: string
  valorLiberado: string
  total: string
  primeiroDesconto: string
  ultimoDesconto: string
  taxaNominalContrato: string
  taxaNominalAnual: string
  jurosContratadosPercentual: string
  jurosContratados: string
  valorTotalDestinadoPagamentoSeguro: string
  percentualPropostaSeguroPrestamista: string
  tarifaCadastro: string
  taxaTarifaCadastro: string
  valorSolicitado: string
  valorSolicitadoPercentual: string
  valorOperacao: string
  valorDoMutuo: string
  atributosCetMensal: string
  atributosCetAnual: string
  dataNascimento: string
  nomeMae: string
  valorTotalASerPago: string
  quantidadeDeParcelas: number
  matricula: string
  tipoLiberacao: string
  jurosCapitalizados: boolean
  estipulante: string
  possuiSeguro: boolean
  dataEmissao: string
  enderecoResidencial: string
  cidadeResidencial: string
  estadoResidencial: string
  bairroResidencial: string
  numeroResidencial: string
  nomeCliente: string
  cpf: string
  nomeConjuge: string
  banco: string
  agencia: string
  conta: string
  nomePai: string
  cidadeNaturalidade: string
  estadoNaturalidade: string
  estadoCivil: string
  nacionalidade: string
  documentoIdentificacao: string
  taxaEfetivaMensal: string
  taxaEfetivaAnual: string
  orgao: string
  complementoResidencial: string
  cepResidencial: string
  cepResidencialComMascara: string
  dddCelular: string
  telefoneCelular: string
  codigoContratoOriginalPortabilidade: string
  bancoPortadoDescricao: string
  digitoContaCorrente: string
  profissao: string
  escolaridade: string
  contratosRefin: ContratosRefinState[]
  saldoDevedorTotalRefin: string
  email: string
}

export interface ContratosRefinState {
  numeroContratoRefin: string
  valorParcelaContratoRefin: string
  quantidadeParcelaContratoRefin: number
  numeroPropostaRefin: string
  saldoDevedorRefin: string
}

export interface DadosPropostaPayload {
  callback: () => void
}
export interface CallbackSalvarProposta {
  callback: () => void
}
export interface CallbackSalvarPropostaSangueLaranja {
  callback: () => void
  erro: () => void
  erroAutorizacao?: () => void
  codigoProposta: string
  token: string
  tipoAutenticacao: string
  valor: string
}

export interface CallbackInfoValida {
  callback: (contato: boolean) => void
}

export interface CriacaoPropostaPayload {
  callback?: () => void
  callbackError?: () => void
}

export interface CriacaoPropostaSangueLaranjaPayload {
  callback?: () => void
  callbackError?: () => void
}

export type SangueLaranjaIdPayload = {
  callback: () => void
}
