import React, { useMemo, useRef, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import routes from '@routes/Refin/routes'
import { sendDocumentosAssinatura } from '@store/refinanciamento/documentosAssinatura/actions'
import { ApplicationState } from '@store/types'
import { useMobileUserInfo } from '@hooks'
import { Loading, Paragraph, Separator } from '@atoms'
import { Checkbox } from '@interco/inter-ui/components/Checkbox'
import { Button } from '@interco/inter-ui/components/Button'
import { Colors } from '@utils/Colors'

import { FooterGrid, PageVisualizarPdf, PdfGrid, TextBoldOrange } from './styles'
import Autorizacao from './Autorizacao'
import Ccb from './CCB'
import DeclaracaoResidencia from './DeclaracaoResidencia'
import SeguroPrestamista from './SeguroPrestamista'
import CCBExercito from './CCBExercito'
import PublicoVulneravel from './PublicoVulneravel'
import Refinanciamento from './Refinanciamento'
import SeguroPrestamistaInss from './SeguroPrestamistaInss'
import ADF from './ADF'
import Portabilidade from './Portabilidade'
import CapaCCB from './CapaCCB'

const VisualizarDocumento = () => {
  const ref = useRef<HTMLDivElement>(null)

  const PdfAssinatura = useMemo(
    () => ({
      AUTORIZACAO_CONSULTA_MARGEM: <Autorizacao />,
      CCB: <Ccb />,
      DECLARACAO_RESIDENCIA: <DeclaracaoResidencia />,
      SEGURO_PRESTAMISTA: <SeguroPrestamista />,
      SEGURO_PRESTAMISTA_INSS: <SeguroPrestamistaInss />,
      CCB_EXERCITO: <CCBExercito />,
      ADF: <ADF />,
      TERMO_PORTABILIDADE: <Portabilidade />,
      TERMO_REFINANCIAMENTO: <Refinanciamento />,
      PUBLICO_VULNERAVEL: <PublicoVulneravel />,
      CCB_CAPA: <CapaCCB />,
      SEGURO_PRESTAMISTA_REFINANCIAMENTO: <SeguroPrestamista />,
    }),
    [],
  )

  const dispatch = useDispatch()
  const { loading, documentoRevisao, checkMessageRevisao, tituloDocumentoRevisao } = useSelector(
    (state: ApplicationState) => state.refinanciamento.documentosAssinatura,
  )
  const [checkAutorizacao, setCheckAutorizacao] = useState(false)
  const isPublicoVulneravel = documentoRevisao === 'PUBLICO_VULNERAVEL'
  const userInfo = useMobileUserInfo()

  const navigate = useNavigate()
  const enableButton = useMemo(() => checkAutorizacao, [checkAutorizacao])

  const onConfirmarClick = () => {
    dispatch(
      sendDocumentosAssinatura({
        tipo: documentoRevisao,
        email: userInfo?.email,
        telefone: userInfo?.cellPhoneNumber,
      }),
    )
    navigate(routes.LISTA_DOCUMENTOS)
  }

  if (loading) return <Loading />

  return (
    <PageVisualizarPdf>
      <PdfGrid ref={ref} isCartilha={isPublicoVulneravel}>
        {PdfAssinatura[documentoRevisao]}
      </PdfGrid>
      <FooterGrid>
        <Separator height="1px" margin="0 -24px 16px -24px" />
        <Checkbox
          id="checkbox-autorizacao"
          reversed
          checked={checkAutorizacao}
          onChange={(e) => setCheckAutorizacao(e.target.checked)}
          label={
            <Paragraph color={Colors.GRAY500} fontSize="12px" lineHeight="15px">
              {checkMessageRevisao}
              {!isPublicoVulneravel && <TextBoldOrange>{tituloDocumentoRevisao}</TextBoldOrange>}
            </Paragraph>
          }
        />
        <Button
          disabled={!enableButton}
          fullWidth
          onClick={() => {
            onConfirmarClick()
          }}
        >
          Confirmar
        </Button>
      </FooterGrid>
    </PageVisualizarPdf>
  )
}

export default VisualizarDocumento
