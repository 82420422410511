import { action, Action } from 'typesafe-actions'

import {
  CallbackLastLiveness,
  ILastLiveness,
  BiometriaTypes,
  CallbackBiometriaDados,
  CallbackBiometriaValidate,
} from './types'

export const setBase64 = (payload: string): Action => action(BiometriaTypes.SET_BASE64, payload)

export const setEncrypted = (payload: string): Action =>
  action(BiometriaTypes.SET_ENCRYPTED, payload)

export const resetLiveness = (): Action => action(BiometriaTypes.RESET_LIVENESS)

export const setLoadingLiveness = (payload: boolean): Action =>
  action(BiometriaTypes.SET_LOADING_LIVENESS, payload)

export const setError = (payload: boolean): Action => action(BiometriaTypes.SET_ERROR, payload)

export const getLastLivenessAction = (payload: CallbackLastLiveness): Action =>
  action(BiometriaTypes.GET_LAST_LIVENESS, payload)

export const setLastLiveness = (payload: ILastLiveness): Action =>
  action(BiometriaTypes.SET_LAST_LIVENESS, payload)

export const setBiometricToken = (payload: string): Action =>
  action(BiometriaTypes.SET_BIOMETRIC_TOKEN, payload)

export const postBiometriaDadoSave = (payload: CallbackBiometriaDados): Action =>
  action(BiometriaTypes.POST_BIOMETRICS_SAVE, payload)

export const getValidateBiometrics = (payload: CallbackBiometriaValidate): Action =>
  action(BiometriaTypes.GET_VALIDATE_BIOMETRICS, payload)

export const setLoadingBimetricsValidate = (payload: boolean): Action =>
  action(BiometriaTypes.SET_LAST_LIVENESS, payload)
